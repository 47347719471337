import { ReactNode } from "react";
import {
  Box,
  Button,
  ButtonVariant,
  Glyph,
  P,
  Space,
  Text,
} from "@gocardless/flux-react";
import {
  AvailableDebitSchemeEnum,
  BillingRequestResource,
  PaylinkResource,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import router from "next/router";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { getPaymentVariant, PaymentVariant } from "src/common/payments";

// TODO: the text isn't quite aligned cause of the sizes. `need to tidy up.
const Heading = ({
  billingRequest,
  paylink,
  plan,
  showBackButton,
}: {
  billingRequest: BillingRequestResource;
  paylink: PaylinkResource | undefined;
  plan?: PlanResource | undefined;
  showBackButton?: boolean;
}) => {
  return (
    <>
      {showBackButton && (
        <Box gutterV={1} className="fs-unmask">
          <Button
            variant={ButtonVariant.Inline}
            leftIcon={Glyph.ArrowBack}
            onClick={() => router.back()}
          >
            <Text size={3}>
              <Trans id="billing-request.heading.go-back-text">Go back</Trans>
            </Text>
          </Button>
        </Box>
      )}
      <Box className="fs-unmask">
        <Text size={[5, 6]}>
          {generateCopy(billingRequest, paylink, plan)}{" "}
        </Text>
      </Box>
    </>
  );
};

// Generate the correct prefixing term, depending on what the Billing Request is
// attempting to create.
const generateCopy = (
  billingRequest: BillingRequestResource,
  paylink: PaylinkResource | undefined,
  plan?: PlanResource | undefined
): string | ReactNode => {
  const creditorName = billingRequest.creditor_name;
  const consentType = billingRequest.mandate_request?.consent_type;
  const paymentVariant = getPaymentVariant(billingRequest, paylink, plan);

  switch (paymentVariant) {
    case PaymentVariant.VariableFirstPaymentWithPlan:
      return (
        <>
          <Text weight={600}>
            Set up your Direct Debit & one-off payment to
          </Text>
          <Space v={0} />
          {creditorName}
        </>
      );
    case PaymentVariant.InstantBankPay:
      return (
        <Trans id="billing-request.heading.ibp">
          <Text weight={600}>Make a one-off instant payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    case PaymentVariant.DualFlow:
    case PaymentVariant.VariableRecurringPaymentsWithFirstPayment:
    case PaymentVariant.DirectDebitRestrictedMandate:
      return (
        <>
          {translateForScheme({
            scheme: billingRequest?.mandate_request
              ?.scheme as AvailableDebitSchemeEnum,
            params: {
              creditorName,
              consentType,
              paymentVariant,
            },
            translationKey: "billing-request.heading.dual-flow",
          })}
        </>
      );

    case PaymentVariant.DirectDebitMandate:
    case PaymentVariant.VerifiedMandate:
    case PaymentVariant.VariableRecurringPayments:
      return (
        <P>
          {translateForScheme({
            scheme: billingRequest?.mandate_request
              ?.scheme as AvailableDebitSchemeEnum,
            params: {
              creditorName,
            },
            translationKey: "billing-request.heading",
          })}
        </P>
      );
    case PaymentVariant.DirectDebitOneOff:
      return (
        <Trans id="billing-request.heading.direct-debit-oneoff">
          <Text weight={600}>Make a one-off payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    default:
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
  }
};

export default Heading;
