import {
  BillingRequestResource,
  ConsentType,
  PaylinkResource,
} from "@gocardless/api/dashboard/types";
import { getPaymentVariant } from "src/common/payments";
import { Scheme } from "src/common/scheme";

import { isInstantBankPay } from "../utils";

import { InstantBankPayment } from "./InstantBankPayment";
import { PADLegalDisclaimer } from "./PADLegalDisclaimer";
import { StandingDebitExplainer } from "./StandingDebitExplainer";

interface EducationWrapperProps {
  billingRequest: BillingRequestResource;
  paylink: PaylinkResource | undefined;
}

export const EducationWrapper = ({
  billingRequest,
  paylink,
}: EducationWrapperProps) => {
  const paymentVariant = getPaymentVariant(billingRequest, paylink);
  const isInstantPay = isInstantBankPay(paymentVariant, billingRequest);

  const hasStandingDebit = [
    ConsentType.Standing,
    ConsentType.Sporadic,
  ].includes(billingRequest.mandate_request?.consent_type as ConsentType);

  if (hasStandingDebit && billingRequest.payment_request) {
    return <StandingDebitExplainer billingRequest={billingRequest} />;
  }

  if (billingRequest.mandate_request?.scheme === Scheme.Pad) {
    return (
      <PADLegalDisclaimer mandateRequest={billingRequest.mandate_request} />
    );
  }

  if (!isInstantPay) {
    return null;
  }

  return <InstantBankPayment billingRequest={billingRequest} />;
};
